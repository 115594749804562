import { getCookie, setCookie } from "../cookies/cookieMgr"
import { validValue } from "@/utils/validUtils"

const reCalcProduct = (state) => {
    console.log("reCalcProduct")
    let order_incl_gst = 0
    state.products.forEach((element) => {
        element.unit_qty = element.qty
        element.order_qty = element.qty
        element.unit_retail = element.retail
        element.incl_gst = element.qty * element.retail
        element.gst = element.incl_gst * 0.15
        element.subtotal = element.incl_gst - element.gst
        order_incl_gst += element.incl_gst
        console.log(element)
    })
    state.order_incl_gst = order_incl_gst
    state.gst = order_incl_gst * 0.15
    state.subtotal = order_incl_gst - state.gst
    console.log(state.products)
    saveCart(state)
}

const loadingCart = (state) => {
    if (state.products.length === 0) {
        let productsJson = getCookie("products")
        let productsData = productsJson ? JSON.parse(productsJson) : []
        if (productsData && productsData.length > 0) {
            state.products = productsData
        }
    }
    if (validValue(state.pickupMethod)) {
        let pickupMethod = getCookie("pickupMethod")
        if (validValue(pickupMethod)) {
            state.pickupMethod = pickupMethod
        }
    }
    if (validValue(state.pickupDate)) {
        let pickupDate = getCookie("pickupDate")
        if (validValue(pickupDate)) {
            state.pickupDate = pickupDate
        }
    }
    if (validValue(state.paymentMethod)) {
        let paymentMethod = getCookie("paymentMethod")
        if (validValue(paymentMethod)) {
            state.paymentMethod = paymentMethod
        }
    }
    if (validValue(state.deliveryFee)) {
        let deliveryFee = getCookie("deliveryFee")
        if (validValue(deliveryFee)) {
            state.deliveryFee = deliveryFee
        } else {
            state.deliveryFee = 0
        }
    }
    if (validValue(state.order_incl_gst)) {
        let order_incl_gst = getCookie("order_incl_gst")
        if (validValue(order_incl_gst)) {
            state.order_incl_gst = order_incl_gst
        } else {
            state.order_incl_gst = 0
        }
    }
    if (validValue(state.gst)) {
        let gst = getCookie("order_gst")
        if (validValue(gst)) {
            state.gst = gst
        } else {
            state.gst = 0
        }
    }
    if (validValue(state.subtotal)) {
        let subtotal = getCookie("order_subtotal")
        if (validValue(subtotal)) {
            state.subtotal = subtotal
        } else {
            state.subtotal = 0
        }
    }
    if (validValue(state.order_id)) {
        let orderId = getCookie("order_id")
        if (validValue(orderId)) {
            state.order_id = orderId
        } else {
            state.order_id = 0
        }
    }
}

const saveCart = (state) => {
    setCookie("products", JSON.stringify(state.products))
    setCookie("deliveryFee", state.deliveryFee)
    setCookie("pickupMethod", state.pickupMethod)
    setCookie("pickupDate", state.pickupDate)
    setCookie("paymentMethod", state.paymentMethod)
    setCookie("order_incl_gst", state.order_incl_gst)
    setCookie("order_gst", state.gst)
    setCookie("order_subtotal", state.subtotal)
    setCookie("order_id", state.order_id)
}

const cartStore = {
    state: {
        products      : [],
        deliveryFee   : 0,    // 配送费
        pickupMethod  : "",   // 取货方式
        pickupDate    : "",   // 取货时间
        paymentMethod : "",   // 支付方式
        order_incl_gst: 0,    // 订单总额
        order_gst     : 0,    // 订单税金
        order_subtotal: 0,    // 订单小计
        order_id      : 0,    // 订单ID
    },
    getters: {
        getCartProducts: (state) => {
            loadingCart(state)
            return state.products
        },
        getCartQty: (state) => {
            loadingCart(state)
            if (state.products && state.products.length > 0) {
                let qty = 0
                state.products.forEach((element) => {
                    qty += element.qty
                })
                return qty
            }
            return 0
        },
        getCartItemCount: (state) => {
            loadingCart(state)
            return state.products.length
        },
        getCartTotal: (state) => {
            loadingCart(state)
            var total = 0
            state.products.forEach((element) => {
                total += element.qty * element.retail
            })
            return total * 100
        },
        getPickupMethod: (state) => {
            return state.pickupMethod
        },
        getPickupDate: (state) => {
            return state.pickupDate
        },
        getPaymentMethod: (state) => {
            return state.paymentMethod
        },
        getDeliveryFee: (state) => {
            console.log(state.deliveryFee)
            return state.deliveryFee
        },
        getOrderInclGST: (state) => {
            return state.order_incl_gst
        },
        getOrderGST: (state) => {
            return state.gst
        },
        getOrderSubtotal: (state) => {
            return state.subtotal
        },
        getOrderId: (state) => {
            return state.order_id
        }
    },
    mutations: {
        addProductToCart(state, product) {
            let isFind = false
            state.products.forEach((element) => {
                if (element.uniqueId === product.uniqueId) {
                    isFind = true
                    element.qty++
                    return
                }
            })
            if (isFind === false) {
                product["qty"] = 1
                state.products.push(product)
            }
            reCalcProduct(state)
        },
        removeProductFromCart(state, product) {
            state.products.splice(state.products.indexOf(product), 1)
            if (state.products.length === 0) {
                state.products = []
            }
            reCalcProduct(state)
        },
        updateProductQty(state, params) {
            let product = params.product
            let qty = params.qty
            console.log("update product qty " + qty)
            state.products[state.products.indexOf(product)].qty = qty
            // remove product if qty is 0
            if (qty === 0) {
                state.products.splice(state.products.indexOf(product), 1)
            }
            reCalcProduct(state)
        },
        cartCalc(state) {
            reCalcProduct(state)
            saveCart(state)
        },
        clearCart(state) {
            state.products = []
            state.pickupMethod = ""
            state.pickupDate = ""
            state.paymentMethod = ""
            state.deliveryFee = 0
            state.order_incl_gst = 0
            state.order_gst = 0
            state.order_subtotal = 0
            saveCart(state)
        },
        setPickupMethod(state, method) {
            state.pickupMethod = method
            reCalcProduct(state)
            saveCart(state)
        },
        setPickupDate(state, date) {
            state.pickupDate = date
            reCalcProduct(state)
            saveCart(state)
        },
        setPaymentMethod(state, method) {
            state.paymentMethod = method
            reCalcProduct(state)
            saveCart(state)
        },
        setOrderId(state, id) {
            state.order_id = id
            saveCart(state)
        }
    },
}

export default cartStore
