// MARK - 通用api URL定义
// ************************************************************************
export const commonApi = {
    FetchBackendVersion: "/common/version/",
    translateContent: "/common/translate/"
}

// MARK - 用户URL定义
// ************************************************************************
export const userApi = {
    FetchUserSales: "/user/sales/",
    FetchAllUser: "/user/",
    FetchAllUserRoleList: "/user/role/list/",
    FetchAllUserPagination: "/user/pagination/",
    FetchUserByID: "/user/",
    CreateUser: "/user/",
    UpdateUser: "/user/update/",
    DeleteUser: "/user/delete/",
    Login: "/user/login/",
    FetchInfo: "/user/info/",
    FetchNav: "/user/nav/"
}

// MARK - 客户URL定义
// ************************************************************************
export const customerApi = {
    FetchCustomerSUmmary: "/customer/summary/",
    FetchCustomerRoleType: "/customer/role-type/",
    FetchAllCustomerPagination: "/customer/pagination/",
    FetchCustomerByID: "/customer/",
    FetchCustomerSalesOrderHistoryByID: "/customer/salesOrder-history/",
    FetchCustomerSalesOrderBalanceByID: "/customer/salesOrder-balance/",
    CreateCustomer: "/customer/",
    UpdateCustomer: "/customer/update/",
    DeleteCustomer: "/customer/delete/",
    ExportAllCustomer: "/customer/export/all/"
}

// MARK - 设置配定义
// ************************************************************************
export const settingsApi = {
    FetchCompanyInfo: "/settings/company-info/",
    FetchCompanyInfoPagination: "/settings/company-info/pagination/",
    FetchCompanyInfoByID: "/settings/company-info/",
    CreateCompanyInfo: "/settings/company-info/",
    UpdateCompanyInfo: "/settings/company-info/update/",
    DeleteCompanyInfo: "/settings/company-info/delete/",
    FetchKeypadList: "/settings/keypad/",
    FetchKeypadById: "/settings/keypad/",
    UpdateKeypadById: "/settings/keypad/update/",
    UpdateKeypadStatus: "/settings/keypad/status/"
}

// MARK - Branch定义
// ************************************************************************
export const branchApi = {
    FetchBranchAll: "/branch/",
    FetchBranchPagination: "/branch/pagination/",
    CreateBranch: "/branch/",
    FetchBranchByID: "/branch/",
    UpdateBranch: "/branch/update/",
    DeleteBranch: "/branch/delete/",
}

// MARK - 支付方式定义
// ************************************************************************
export const paymentApi = {
    FetchAll: "/payment/fetch/all/"
}

// MARK - Stripe定义
// ************************************************************************
export const stripeApi = {
    Process: "/stripe/process/",
}

// MARK - 定时任务定义
// ************************************************************************
export const scheduleApi = {
    FetchSchedulePagination: "/schedule/pagination/",
    FetchScheduleByID: "/schedule/",
    UpdateScheduleByID: "/schedule/",
}

// MARK - 上传URL定义
// ************************************************************************
export const uploadApi = {
    UploadImage: "/upload/image/",
    UploadImageBySquare: "/upload/image/square/",
    UploadImageData: "/upload/image-data/",
    UploadDoc: "/upload/doc/"
}

// MARK - 商品URL定义
// ************************************************************************
export const productApi = {
    RandomSKU: "/product/sku/gen/",
    ExportAll: "/product/export/all/",
    ExportForScanner: "/product/export/scanner/",
    ExportInStock: "/product/export/in-stock/",
    FetchAll: "/product/",
    FetchByProductID: "/product/",
    FetchStockDetailBySKUPagination: "/product/stock/detail/pagination/",
    Create: "/product/",
    UpdateByProductID: "/product/",
    DeleteByProductID: "/product/delete/",
    activateByProductID: "/product/activate/",
    inactivateByProductID: "/product/inactivate/",
    activateBulk: "/product/activate/bulk/",
    inactivateBulk: "/product/inactivate/bulk/",
    deleteBulk: "/product/delete/bulk/",
    FetchProductStockByDateDownload: "/product/stock/download/",
    UpdateProductByXlsUrl: "/product/update_by_xls/",
    FetchProductSalesHistoryByIDPagination: "/product/sales-history/pagination/",
}

// MARK - 商品分类URL定义
// ************************************************************************
export const productCategoryApi = {
    FetchAll: "/product/category/",
    FetchPagination: "/product/category/pagination/",
    Create: "/product/category/",
    UpdateByCategoryID: "/product/category/",
    DeleteByCategoryID: "/product/category/delete/"
}

// MARK - 商品BrandURL定义
// ************************************************************************
export const productBrandApi = {
    FetchAll: "/product/brand/",
    FetchPagination: "/product/brand/pagination/",
    Create: "/product/brand/",
    UpdateByBrandID: "/product/brand/",
    DeleteByBrandID: "/product/brand/delete/"
}

// MARK - 商品Supplier URL定义
// ************************************************************************
export const productSupplierApi = {
    FetchAll: "/product/supplier/",
    Create: "/product/supplier/",
    FetchPagination: "/product/supplier/pagination/",
    UpdateBySupplierID: "/product/supplier/",
    DeleteBySupplierID: "/product/supplier/delete/"
}

// MARK - 商品Tag URL定义
// ************************************************************************
export const productTagApi = {
    FetchAll: "/product/tag/",
    Create: "/product/tag/",
    FetchPagination: "/product/tag/pagination/",
    UpdateByTagID: "/product/tag/",
    DeleteByTagID: "/product/tag/delete/"
}

// MARK - Promo URL定义
// ************************************************************************
export const PromoApi = {
    FetchAll: "/promo/",
    FetchPagination: "/promo/pagination/",
    FetchByPromoID: "/promo/",
    Create: "/promo/",
    UpdateByPromoID: "/promo/update/",
    DeleteByPromoID: "/promo/delete/"
}

// MARK - 搜索URL定义
// ************************************************************************
export const searchApi = {
    SearchProductByKeyword: "/search/product/",
    SearchProductAdvanced: "/search/product/advanced/",
    SearchCustomerByKeyword: "/search/customer/",
    SearchUserByKeywordPagination: "/search/user/pagination/",
    SearchCustomerByKeywordPagination: "/search/customer/pagination/",
    SearchProductByKeywordPagination: "/search/product/pagination/",
    SearchPurchaseOrderByKeyword: "/search/purchaseOrder/",
    SearchPurchaseOrderByKeywordPagination: "/search/purchaseOrder/pagination/",
    SearchSalesOrderByKeyword: "/search/salesOrder/",
    SearchSalesOrderByKeywordPagination: "/search/salesOrder/pagination/"
}

// MARK - 采购订单PurchaseOrder订单
// ************************************************************************
export const purchaseOrderApi = {
    FetchStatusType: "/purchaseOrder/status-type/",
    FetchAll: "/purchaseOrder/",
    FetchByOrderID: "/purchaseOrder/",
    Create: "/purchaseOrder/",
    UpdateByOrderID: "/purchaseOrder/",
    DeleteByOrderID: "/purchaseOrder/delete/",
    genProductListByUploadFile: '/purchaseOrder/gen_product_list/',
    exportByOrderID: "/purchaseOrder/export/",
}

// MARK - 销售订单SalesOrder订单
// ************************************************************************
export const salesOrderApi = {
    FetchAll: "/salesOrder/",
    exportAll: "/salesOrder/export/",
    exportOrderItems: "/salesOrder/export/order-items/",
    FetchChannelType: "/salesOrder/channel-type/",
    FetchStatusType: "/salesOrder/status-type/",
    FetchCourierType: "/salesOrder/courier-type/",
    FetchByOrderID: "/salesOrder/",
    SendInvEmail: "/salesOrder/send-inv/",
    FetchBulk: "/salesOrder/bulk/",
    Create: "/salesOrder/",
    UpdateByOrderID: "/salesOrder/",
    DeleteByOrderID: "/salesOrder/delete/",
    DispatchedByOrderID: "/salesOrder/dispatched/",
    PaidByOrderID: "/salesOrder/paid/",
    BulkUpdateStatus: "/salesOrder/bulk/status/"
}

// MARK - 报表
// ************************************************************************
export const reportApi = {
    OrderingReport: "/report/ordering-report/",
    OrderingReportDownload: "/report/ordering-report/download/",
    ProductReport: "/report/product-report/",
    ProductReportDownload: "/report/product-report/download/",
    ProductSalesDetailReport: "/report/product-sales-detail-report/",
    TopProductReport: "/report/top-product-report/",
    SchedulerReport: "/report/scheduler-report/",
    SalesReport: "/report/sales-report/",
}

// MARK - dashboard
// ************************************************************************
export const dashboardApi = {
    Dashboard: "/dashboard/",
    GenTodaySales: "/dashboard/gen/today/",
    GenWeekSales: "/dashboard/gen/week/",
    GenMonthSales: "/dashboard/gen/month/",
    GenTotalSales: "/dashboard/gen/total/"
}

// MARK - loyalty
// ************************************************************************
export const loyaltyApi = {
    FetchSettings: "/loyalty/settings/",
    UpdateSettings: "/loyalty/settings/"
}

// MARK - H5 Customer
// ************************************************************************
export const h5CustomerApi = {
    CustomerLogin: "/h5/customer/login/",
    CustomerRegister: "/h5/customer/register/",
    FetchUserInfo: "/h5/customer/info/",
}

// MARK - H5 Product
// ************************************************************************
export const h5ProductApi = {
    FetchProductAll: "/h5/product/all/",
}

// MARK - H5 Sales Order
// ************************************************************************
export const h5SalesOrderApi = {
    CreateSalesOrder: "/h5/sales-order/",
    FetchSalesOrderByCustomerId: "/h5/sales-order/customer/",
    FetchSalesOrderStatusType: "/h5/sales-order/status-type/",
}

// MARK - h5 settings common
// ************************************************************************
export const h5SettingsCommonApi = {}

// MARK - H5 settings home
// ************************************************************************
export const h5SettingsHomeApi = {
    // banner
    FetchH5HomeBanner: "/h5-settings/home/banner/",
    DeleteH5HomeBannerById: "/h5-settings/home/banner/delete/",
    UpdateH5HomeBannerById: "/h5-settings/home/banner/update/",
    UpdateH5HomeBannerByBulk: "/h5-settings/home/banner/update/bulk/",
    // section
    FetchH5HomeSection: "/h5-settings/home/section/",
    DeleteH5HomeSectionById: "/h5-settings/home/section/delete/",
    UpdateH5HomeSectionById: "/h5-settings/home/section/update/",
    UpdateH5HomeSectionByBulk: "/h5-settings/home/section/update/bulk/",
    // hot product
    FetchH5HomeHotProduct: "/h5-settings/home/hot-product/",
    DeleteH5HomeHotProductById: "/h5-settings/home/hot-product/delete/",
    UpdateH5HomeHotProductById: "/h5-settings/home/hot-product/update/",
    UpdateH5HomeHotProductByBulk: "/h5-settings/home/hot-product/update/bulk/",
    // limit product
    FetchH5HomeLimitProduct: "/h5-settings/home/limit-product/",
    DeleteH5HomeLimitProductById: "/h5-settings/home/limit-product/delete/",
    UpdateH5HomeLimitProductById: "/h5-settings/home/limit-product/update/",
    UpdateH5HomeLimitProductByBulk: "/h5-settings/home/limit-product/update/bulk/"
}


// MARK - Admin-H5: ProductCategory
// ************************************************************************
export const ah5ProductCategoryApi = {
    FetchRandomUncategorisedProduct: "/admin-h5/product-category/random-uncategorised-product/",
    UpdateProductCategory: "/admin-h5/product-category/update/"
}
