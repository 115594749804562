<template>
    <div class="LangSwitchCom">
        <van-floating-bubble
            @click="onLanguageChange"
            axis="clock"
            v-model:offset="offset"
            style="background-color: transparent"
        >
            <!-- :style="{ 'background-color': langColor }" -->
            <template #default>
                <Icon :icon="langIcon" style="font-size: 48px"></Icon>
            </template>
        </van-floating-bubble>
    </div>
</template>
<script setup>
import { useI18n } from "vue-i18n"
import { ref } from "vue"
import { Icon } from "@iconify/vue"

// MARK - Props
// ************************************************************************
const { locale } = useI18n()
const offset = ref({ x: -4, y: 4 }) // 设置浮动气泡的偏移量
const lang = ref(locale.value === "en-US" ? "中文" : "EN") // 设置浮动气泡的文本
const langIcon = ref(
    locale.value === "en-US" ? "icon-park:chinese" : "icon-park:english"
) // 设置浮动气泡的图标
const langColor = ref(locale.value === "en-US" ? "red" : "blue") // 设置浮动气泡的颜色

// MARK - LIFE CYCLE
// ************************************************************************

// MARK - Methods
// ************************************************************************
const onLanguageChange = () => {
    console.log("onLanguageChange")
    console.log(locale)
    locale.value = locale.value === "en-US" ? "zh-CN" : "en-US"
    lang.value = locale.value === "en-US" ? "中文" : "EN"
    langColor.value = locale.value === "en-US" ? "red" : "blue"
    langIcon.value =
        locale.value === "en-US" ? "icon-park:chinese" : "icon-park:english"
}
</script>
<style>
.LangSwitchCom {
    width: 100%;
    height: 100%;
}
</style>
